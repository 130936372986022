var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "faq"
  }, _vm._l(_vm.items, function (name, i) {
    return _c('div', {
      key: i,
      staticClass: "item",
      class: _vm.$mq
    }, [_c('div', {
      staticClass: "question"
    }, [_vm._t(`${name}:question`)], 2), _c('div', {
      staticClass: "answer"
    }, [_vm._t(`${name}:answer`)], 2)]);
  }), 0);
};
var staticRenderFns = [];
export { render, staticRenderFns };