var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "period-selector",
    class: _vm.$mq
  }, _vm._l(_vm.items, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "item",
      class: {
        active: _vm.value === item
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClick(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name)), item.discount ? [_c('span', {
      staticClass: "badge"
    }, [_vm._v("-" + _vm._s(item.discount) + "%")])] : _vm._e()], 2);
  }), 0);
};
var staticRenderFns = [];
export { render, staticRenderFns };