import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { SlideYUpTransition } from "vue2-transitions";
export default {
  model: {
    prop: "modelValue",
    event: "select"
  },
  props: {
    absoluteTitle: {
      type: String,
      default: ""
    },
    items: {
      required: true
    },
    modelValue: {
      default: null
    },
    openUp: {
      type: Boolean,
      default: false
    },
    mini: {
      type: Boolean,
      default: false
    },
    width: {
      default: 200
    },
    fullwidth: {
      required: false,
      default: false,
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false
    },
    iconMisc: {
      required: false,
      default: false,
      type: Boolean
    },
    trim: {
      type: Boolean,
      default: false
    },
    popupGrow: {
      type: Boolean,
      default: false
    },
    popupAlignRight: {
      type: Boolean,
      defaut: false
    },
    search: {
      type: Boolean,
      defaut: false
    }
  },
  data() {
    return {
      isOpen: false,
      searchValue: ""
    };
  },
  computed: {
    selectedItem() {
      if (this.modelValue instanceof Object) {
        return this.modelValue;
      }
      return this.items[0];
    },
    visibleItems() {
      return this.items.filter(i => {
        return typeof i.title === "string" ? !i.hide && i.title.includes(this.searchValue) : !i.hide;
      });
    }
  },
  methods: {
    selectItem(item) {
      if (item.disabled) {
        return;
      }
      if (this.disabled) {
        return;
      }
      this.$emit("select", item);
      this.closeSelect();
    },
    closeSelect() {
      this.isOpen = false;
    },
    openSelect() {
      this.isOpen = true;
    },
    toggleSelect() {
      this.isOpen = !this.isOpen;
    }
  },
  components: {
    SlideYUpTransition
  }
};