var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "index-page",
    class: _vm.$mq
  }, [_c('img', {
    staticClass: "index-page__background",
    class: _vm.$mq,
    attrs: {
      "src": require(`@/assets/images/plans_bg.svg`)
    }
  }), _c('div', {
    staticClass: "lights"
  }, [_c('svg', {
    attrs: {
      "width": "1440",
      "height": "770",
      "viewBox": "0 0 1440 770",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "opacity": "0.5",
      "filter": "url(#filter0_f_1402_8463)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M588.078 227.574C588.078 227.574 622.205 307.177 612.14 336.215C602.076 365.253 309.789 448.779 286.111 440.573C262.434 432.366 261.838 361.584 271.902 332.546C281.966 303.508 588.078 227.574 588.078 227.574Z",
      "fill": "#A09EFF",
      "fill-opacity": "0.85"
    }
  })]), _c('g', {
    attrs: {
      "opacity": "0.5",
      "filter": "url(#filter1_f_1402_8463)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M1413.08 -93.4255C1413.08 -93.4255 1447.2 -13.823 1437.14 15.2152C1427.08 44.2533 1134.79 127.779 1111.11 119.573C1087.43 111.366 1086.84 40.584 1096.9 11.5459C1106.97 -17.4923 1413.08 -93.4255 1413.08 -93.4255Z",
      "fill": "#FFECF3",
      "fill-opacity": "0.85"
    }
  })]), _c('defs', [_c('filter', {
    attrs: {
      "id": "filter0_f_1402_8463",
      "x": "-62.2729",
      "y": "-100.426",
      "width": "1004.27",
      "height": "869.562",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "BackgroundImageFix",
      "result": "shape"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "164",
      "result": "effect1_foregroundBlur_1402_8463"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter1_f_1402_8463",
      "x": "762.727",
      "y": "-421.426",
      "width": "1004.27",
      "height": "869.562",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "BackgroundImageFix",
      "result": "shape"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "164",
      "result": "effect1_foregroundBlur_1402_8463"
    }
  })], 1)])])]), _c('public-header'), _c('section', {
    staticClass: "hero"
  }, [_c('h1', {
    staticClass: "h1 hero__title",
    class: _vm.$mq
  }, [_vm._v("Тарифы и цены")]), _c('div'), _c('div', {
    staticClass: "hero__period",
    class: _vm.$mq
  }, [_c('PeriodSelector', {
    attrs: {
      "items": _vm.periods
    },
    model: {
      value: _vm.activePeriod,
      callback: function ($$v) {
        _vm.activePeriod = $$v;
      },
      expression: "activePeriod"
    }
  }), _c('div', {
    staticClass: "hero__alert"
  }, [_vm.type != 'addLimit' ? _c('FireAlert', {
    staticStyle: {
      "margin-bottom": "30px"
    }
  }, [_vm._v(" Новогодняя распродажа в SalesFinder! "), _c('br'), _vm._v(" Выбирайте подарок сами: скидка до 50% или 12 месяцев в подарок! "), _c('a', {
    attrs: {
      "href": "https://www.help.salesfinder.ru/ny_sale",
      "target": "_blank"
    }
  }, [_vm._v(" Узнать подробности >> ")])]) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "hero__cards",
    class: _vm.$mq
  }, [_c('PlanCards', {
    attrs: {
      "period": _vm.activePeriod
    }
  })], 1)]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h2', {
    staticClass: "h2 section__title"
  }, [_vm._v("Нам доверяют")]), _c('div', {
    staticClass: "review-wrapper"
  }, [_c('Review')], 1)])]), _c('section', {
    staticClass: "section section-last"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h2', {
    staticClass: "h2 section__title"
  }, [_vm._v("Вопросы и ответы")]), _c('div', {
    staticClass: "faq-wrapper"
  }, [_c('PFaq', {
    scopedSlots: _vm._u([{
      key: "1:question",
      fn: function () {
        return [_vm._v(" Вы работаете с юридическими лицами? ")];
      },
      proxy: true
    }, {
      key: "1:answer",
      fn: function () {
        return [_c('p', [_vm._v(" Да, конечно. Мы работаем с юридическими лицами на основании публичной оферты, подписывать бумажный договор при этом не требуется. ")]), _c('p', [_vm._v(" Для оформления счета на оплату нужно заполнить реквизиты организации выбрать метод оплаты “Безналичный расчет”. ")]), _c('p', [_vm._v(" Наша организация работает на УСН, счета выставляются без НДС. ")]), _c('p', [_vm._v(" Закрывающие документы вы можете получить почтой или через систему электронного документооборота (ЭДО). ")])];
      },
      proxy: true
    }, {
      key: "2:question",
      fn: function () {
        return [_vm._v(" Какие способы оплаты доступны? ")];
      },
      proxy: true
    }, {
      key: "2:answer",
      fn: function () {
        return [_c('p', [_vm._v(" Вы можете оплатить подписку банковской картой (Visa, MasterCard, МИР), электронными деньгами (Ю-Money) или безналичным переводов по счету (для юридических лиц). ")])];
      },
      proxy: true
    }, {
      key: "3:question",
      fn: function () {
        return [_vm._v(" Чем отличаются тарифы? Как выбрать подходящий? ")];
      },
      proxy: true
    }, {
      key: "3:answer",
      fn: function () {
        return [_c('p', [_vm._v(" Тарифы отличаются доступным функционалом и лимитами. Например, в тарифе Start вы можете делать 600 запросов (отчетов) в сутки и включен 1 пользователь. А в тарифе Business доступно уже 2000 отчетов в сутки и 2 пользователя в рамках одного аккаунта. Мы рекомендуем тариф Business, как оптимальный по соотношению функционала и стоимости. Также вы в любой момент можете сменить тариф. ")])];
      },
      proxy: true
    }, {
      key: "4:question",
      fn: function () {
        return [_vm._v(" Что означает “Запросов в сутки”? ")];
      },
      proxy: true
    }, {
      key: "4:answer",
      fn: function () {
        return [_c('p', [_vm._v(" Каждый запрос к базе данных SalesFinder расходует 1 лимит из доступных запросов в сутки. Например, вы запросили отчет по анализу бренда Lego - 1 запрос, после перешли к анализу другого бренда или категории - это еще 1 запрос и так далее. ")])];
      },
      proxy: true
    }, {
      key: "5:question",
      fn: function () {
        return [_vm._v(" Можно ли сменить тариф в будущем? ")];
      },
      proxy: true
    }, {
      key: "5:answer",
      fn: function () {
        return [_c('p', [_vm._v(" Да, конечно. Сменить тариф вы можете в любой момент в личном кабинете. Если вы переходите на более старший тариф, то мы автоматически посчитаем сумму доплаты за переход (с учетом ранее оплаченных средств и израсходованных дней по текущему тарифу). ")])];
      },
      proxy: true
    }, {
      key: "6:question",
      fn: function () {
        return [_vm._v(" Могу ли я получить скидку? ")];
      },
      proxy: true
    }, {
      key: "6:answer",
      fn: function () {
        return [_c('p', [_vm._v(" Разумеется! При оплате подписки на 3, 6 или 12 месяцев вы автоматически получаете скидку 10%, 20% и 30% соответственно. ")]), _c('p', [_vm._v(" Также мы предоставляем дополнительные скидки при покупке более 3 аккаунтов. ")]), _c('p', [_c('router-link', {
          attrs: {
            "to": {
              name: 'Contact'
            }
          }
        }, [_vm._v("Свяжитесь с нами")]), _vm._v(", если вашей компании требуется большое кол-во аккаунтов или пользователей в рамках одного аккаунта. ")], 1)];
      },
      proxy: true
    }, {
      key: "7:question",
      fn: function () {
        return [_vm._v(" Нужен custom-тариф или индивидуальный функционал? ")];
      },
      proxy: true
    }, {
      key: "7:answer",
      fn: function () {
        return [_c('p', [_c('router-link', {
          attrs: {
            "to": {
              name: 'Contact'
            }
          }
        }, [_vm._v("Свяжитесь с нами")]), _vm._v(" через обратную связь - мы всегда рады помочь и предложить решения для ваших бизнес-задач! ")], 1)];
      },
      proxy: true
    }])
  })], 1)])]), _c('public-footer')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };