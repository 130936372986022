var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "review",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "left"
  }, [_c('svg', {
    staticClass: "bg",
    attrs: {
      "width": "250",
      "height": "180",
      "viewBox": "0 0 250 180",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M107.353 43.5923C94.9346 43.9162 85.049 45.9403 77.6961 49.6648C70.3431 53.2273 64.5425 58.8949 60.2941 66.6676C77.2876 66.6676 91.3399 70.7969 102.451 79.0554C113.725 87.3139 119.363 100.754 119.363 119.376C119.363 126.339 118.137 133.141 115.686 139.78C113.235 146.419 109.477 152.33 104.412 157.511C99.3464 162.531 93.219 166.741 86.0294 170.142C78.8399 173.381 70.5882 175 61.2745 175C42.8105 175 27.9412 169.089 16.6667 157.268C5.55556 145.286 0 129.335 0 109.418C0 98.4062 1.55229 87.3139 4.65686 76.1406C7.92484 64.8054 13.6438 54.3608 21.8137 44.8068C29.9837 35.0909 41.0131 26.5895 54.902 19.3026C68.7909 12.0156 86.2745 6.91477 107.353 4V43.5923ZM237.99 43.5923C225.572 43.9162 215.686 45.9403 208.333 49.6648C200.98 53.2273 195.098 58.8949 190.686 66.6676C207.843 66.6676 221.977 70.7969 233.088 79.0554C244.363 87.3139 250 100.754 250 119.376C250 126.339 248.693 133.141 246.078 139.78C243.627 146.419 239.869 152.33 234.804 157.511C229.902 162.531 223.856 166.741 216.667 170.142C209.477 173.381 201.144 175 191.667 175C173.366 175 158.578 169.089 147.304 157.268C136.029 145.286 130.392 129.335 130.392 109.418C130.392 98.4062 131.944 87.3139 135.049 76.1406C138.317 64.8054 144.036 54.3608 152.206 44.8068C160.539 35.0909 171.65 26.5895 185.539 19.3026C199.428 12.0156 216.912 6.91477 237.99 4V43.5923Z",
      "fill": "#D1D0FF"
    }
  })]), _c('div', {
    staticClass: "left-content"
  }, [_c('div', [_c('div', {
    staticClass: "avatar-wrapper"
  }, [_c('img', {
    staticClass: "avatar",
    attrs: {
      "width": "64",
      "height": "64",
      "src": require(`./img/customer_review_starkov.png`)
    }
  })])]), _c('div', {
    staticClass: "name"
  }, [_vm._v("Андрей Старков")]), _c('div', {
    staticClass: "position"
  }, [_vm._v(" Создатель бренда Advanced Nutrition и владелец сети магазинов Planka ")])])]), _c('div', {
    staticClass: "right",
    class: _vm.$mq
  }, [_vm._v(" “Оптимальный инструмент внешней аналитики на сегодняшний день. Сервис дает полную картину и отлично отслеживает данные продаж не только на Wildberries, но и на OZON. Перед запуском новых продуктов всегда обращаюсь к данным SalesFinder.” ")])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };