export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  methods: {
    onClick(item) {
      this.$emit("input", item);
    }
  }
};