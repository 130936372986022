import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.some.js";
import ModalPayment from "@/components/Billing/ModalPayment.vue";
import NewFeature from "@/components/NewFeature.vue";
export default {
  components: {
    ModalPayment,
    NewFeature
  },
  props: {
    period: {
      type: Object,
      required: true
    }
  },
  watch: {
    period: {
      handler() {
        this.calculateOrder();
      }
    }
  },
  data() {
    return {
      plans: [],
      calculated: null,
      calculatedForPeriod: null,
      loadGatewayScriptPromise: null
    };
  },
  async mounted() {
    this.plans = await this.$store.dispatch("billing/getPlans");
    this.calculateOrder();
  },
  methods: {
    isPlanActive(plan) {
      if (this.$store.state.user.user && !this.$store.state.user.user.parent && this.$store.state.billing.currentSubscriptions && this.$store.state.billing.currentSubscriptions.some(i => i.plan.id === plan.id)) {
        return true;
      }
      return false;
    },
    buildOrderItem({
      plan
    }) {
      return {
        plan,
        months: this.period.months,
        discounts: this.period.discount ? [{
          type: "quantity",
          quantity: this.period.months,
          percent: this.period.discount
        }] : []
      };
    },
    async calculateOrder() {
      //console.log('calculateOrder(): this.plans='); console.dir(this.plans);

      this.calculated = await this.$store.dispatch("billing/calculateOrder", {
        items: this.plans.map(plan => this.buildOrderItem({
          plan: plan
        })),
        type: "first"
      });

      //console.log("calculateOrder(): this.calculated=");
      //console.dir(this.calculated);

      this.calculatedForPeriod = this.period;
    },
    loadGatewayScript() {
      if (this.loadGatewayScriptPromise) {
        return this.loadGatewayScriptPromise;
      }
      return this.loadGatewayScriptPromise = new Promise(resolve => {
        const scriptElement = document.createElement("script");
        scriptElement.type = "text/javascript";
        scriptElement.src = `//auth.robokassa.ru/Merchant/bundle/robokassa_iframe.js`;
        scriptElement.async = true;
        scriptElement.onload = () => {
          resolve();
        };
        const targetElement = document.getElementsByTagName("script")[0];
        targetElement.parentNode.insertBefore(scriptElement, targetElement);
      });
    },
    async onChoose(plan) {
      if (this.$store.state.user.user) {
        this.$router.push({
          name: "ProfilePlan",
          params: {
            plan,
            period: this.period.months
          }
        });
      } else {
        this.$router.push({
          name: "Register"
        });
      }
    }
  }
};