var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "select",
    class: {
      'select-mini': _vm.mini,
      disabled: _vm.disabled,
      trim: _vm.trim,
      popupGrow: _vm.popupGrow,
      popupAlignRight: _vm.popupAlignRight
    }
  }, [_vm.absoluteTitle ? _c('div', {
    staticClass: "absolute-title"
  }, [_vm._v(" " + _vm._s(_vm.absoluteTitle) + " ")]) : _vm._e(), _c('div', {
    staticClass: "select__main",
    class: {
      'select-open': _vm.isOpen,
      icon: _vm.iconMisc
    },
    style: [_vm.fullwidth ? {
      width: `100%`
    } : {
      width: `${_vm.width}px`
    }, _vm.selectedItem && _vm.selectedItem.id == 0 ? {
      color: _vm.selectedItem.color
    } : ''],
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.toggleSelect.apply(null, arguments);
      }
    }
  }, [!_vm.iconMisc ? [_c('span', {
    staticClass: "current-text"
  }, [_vm.selectedItem ? [_vm._v(" " + _vm._s(_vm.selectedItem.title) + " ")] : [_vm._v("   ")]], 2)] : _vm._e(), !_vm.iconMisc ? _c('svg', {
    staticClass: "select-arrow",
    class: {
      'select-arrow-mini': _vm.mini
    },
    attrs: {
      "width": "12",
      "height": "8",
      "viewBox": "0 0 12 8",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M10.293 0.439453L5.99997 4.73245L1.70697 0.439453L0.292969 1.85345L5.99997 7.56045L11.707 1.85345L10.293 0.439453Z",
      "fill": "black"
    }
  })]) : _c('svg', {
    attrs: {
      "width": "4",
      "height": "12",
      "viewBox": "0 0 4 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M2 4.5C1.175 4.5 0.5 5.175 0.5 6C0.5 6.825 1.175 7.5 2 7.5C2.825 7.5 3.5 6.825 3.5 6C3.5 5.175 2.825 4.5 2 4.5ZM2 0C1.175 0 0.5 0.675 0.5 1.5C0.5 2.325 1.175 3 2 3C2.825 3 3.5 2.325 3.5 1.5C3.5 0.675 2.825 0 2 0ZM2 9C1.175 9 0.5 9.675 0.5 10.5C0.5 11.325 1.175 12 2 12C2.825 12 3.5 11.325 3.5 10.5C3.5 9.675 2.825 9 2 9Z",
      "fill": "black",
      "fill-opacity": "0.9"
    }
  })])], 2), _c('slide-y-up-transition', [_vm.isOpen ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeSelect,
      expression: "closeSelect"
    }],
    staticClass: "select__popup",
    class: {
      'select__popup-up': _vm.openUp,
      'open-bottom-left': _vm.iconMisc
    }
  }, [_vm.search ? _c('div', {
    staticClass: "search-wrap"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchValue,
      expression: "searchValue"
    }],
    staticClass: "search",
    attrs: {
      "type": "text",
      "placeholder": "Поиск...",
      "autofocus": ""
    },
    domProps: {
      "value": _vm.searchValue
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchValue = $event.target.value;
      }
    }
  })]) : _vm._e(), _vm._l(_vm.visibleItems, function (i) {
    return _c('div', {
      key: i.id,
      staticClass: "select__item",
      class: {
        'select__item-active': _vm.selectedItem == i,
        'select__item-disabled': i.disabled
      },
      on: {
        "click": function ($event) {
          return _vm.selectItem(i);
        }
      }
    }, [_vm._v(" " + _vm._s(i.title) + " ")]);
  })], 2) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };